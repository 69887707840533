<template>
	<div class="pc-container">
		<div class="main">
			<BACK url="/team" />
			<div class="box">
				<div class="img-wrapper">
					<img :class="['img', { 'fade-up': showHover }]"
					:src="showHover ? detail.pic2 : detail.pic1" 
					@mouseenter="showHover = true" 
					@mouseleave="showHover = false" 
					:style="{ height: imgHeight + 'px' }" />
				</div>
				<div class="info">
					<div class="position">{{detail.tags}}</div>
					<div class="name">{{detail.name}}</div>
					<div class="desc">{{detail.intro}}</div>
					<div v-html="detail.content" class="content"></div>
					<div :class="['bottom-nav', { 'right-nav': !previous }]">
						<div v-if="previous" class="previous" @click="toDetail(previous.id)">
							<i class="el-icon-arrow-left arrow-icon"></i>
							<div class="intro">
								<div class="label">{{$t('Common.Previous')}}</div>
								<div class="person-name">{{previous.name}}</div>
							</div>
						</div>
						<div v-if="next" class="next" @click="toDetail(next.id)">
							<div class="intro">
								<div class="label">{{$t('Common.Next')}}</div>
								<div class="person-name">{{next.name}}</div>
							</div>
							<i class="el-icon-arrow-right arrow-icon"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    BACK: () => import('@/components/back.vue')
		},
        data() {
            return {
				imgHeight: 0,
				persons: [],
				detail: {},
				showHover: false
            }
        },
		created() {
			let list = localStorage.getItem('api-cache-team_person-{}')
			this.persons = list ? JSON.parse(list).data.data.persons : []
			
			let id = this.$route.query.id || ''
		    this.getDetail(id)
		},
		mounted() {
		    // 首次加载时,需要调用一次
		    this.setSize()
		    // 窗口大小发生改变时,调用一次
		    window.onresize = () => {
		        this.setSize()
		    }
		},
		computed: {
			curId() {
			    return this.$route.query.id
			},
			
			previous() {
				let cur_index = -1
				this.persons.forEach((item, index) => {
					if(item.id == this.detail.id) {
						cur_index = index
					}
				})
				if(cur_index == 0) {
					return ''
				} else {
					return cur_index == -1 ? '' : this.persons[cur_index - 1]
				}
			},
			
			next() {
				let cur_index = -1
				this.persons.forEach((item, index) => {
					if(item.id == this.detail.id) {
						cur_index = index
					}
				})
				if(cur_index == this.persons.length - 1) {
					return ''
				} else {
					return cur_index == -1 ? '' : this.persons[cur_index + 1]
				}
			}
		},
		watch: {
		    curId(val) {
		        // console.log(val)
		        if(val) {
		            this.getDetail(val)
		        }
		    }
		},
        methods: {
			setSize() {
			    // 获取Banner宽度
			    let img_width = document.getElementsByClassName('img-wrapper')[0].clientWidth
			    // 获取Banner高度
			    this.imgHeight = img_width * 1.5
			},
			
			getDetail(id) {
				this.$api.team_person_detail({
					id
				}).then(res => {
					this.detail = res.data
				})
			},
			
			toDetail(id) {
				this.$router.push({
					path: '/TeamDetail',
					query: {
						id
					}
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
	.pc-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		box-sizing: border-box;
		padding: 50px 0;
		background-color: #f6f6f6;
		
		.main {
			width: 1200px;
			
			.box {
				display: flex;
				justify-content: space-between;
				width: 100%;
				
				.img-wrapper {
					min-width: 280px;
					max-width: 280px;
					height: 0;
					padding-bottom: 420px;
					margin-right: 24px;
					border-radius: 6px;
					overflow: hidden;
					
					@keyframes fadeUp {
						0% {
							opacity: 0;
						}
					
						100% {
							opacity: 1;
						}
					}
					
					.img {
						display: block;
						width: 100%;
						border-radius: 6px;
					}
					
					.fade-up {
						animation: fadeUp .5s;
						animation-timing-function: linear;
						-webkit-animation: fadeUp .5s;
						-webkit-animation-timing-function: linear;
					}
				}
				
				.info {
					width: 100%;
					
					.position {
						width: 100%;
						margin-bottom: 14px;
						font-size: 17px;
						font-weight: bold;
						color: #5466EF;
						word-wrap: break-word;
					}
					
					.name {
						width: 100%;
						margin-bottom: 20px;
						font-size: 44px;
						font-weight: bold;
						color: #000000;
						word-wrap: break-word;
					}
					
					.desc {
						width: 100%;
						margin-bottom: 20px;
						font-size: 20px;
						font-weight: bold;
						color: #333333;
						word-wrap: break-word;
					}
					
					.content {
						width: 100%;
						word-wrap: break-word;
					}
					
					.bottom-nav {
						display: flex;
						justify-content: space-between;
						align-items: center;
						width: 100%;
						margin-top: 40px;
						
						.previous {
							display: flex;
							align-items: center;
							max-width: 48.5%;
							cursor: pointer;
							
							.arrow-icon {
								margin-right: 12px;
								font-size: 32px;
								font-weight: bold;
								color: #999999;
							}
							
							.intro {
								width: 100%;
								
								.label {
									width: 100%;
									margin-bottom: 5px;
									font-size: 14px;
									color: #666666;
									word-wrap: break-word;
								}
								
								.person-name {
									width: 100%;
									font-size: 18px;
									color: #000000;
									word-wrap: break-word;
								}
							}
						}
						
						.next {
							display: flex;
							align-items: center;
							max-width: 48.5%;
							cursor: pointer;
							
							.intro {
								width: 100%;
								
								.label {
									width: 100%;
									margin-bottom: 5px;
									font-size: 14px;
									color: #666666;
									word-wrap: break-word;
								}
								
								.person-name {
									width: 100%;
									font-size: 18px;
									color: #000000;
									word-wrap: break-word;
								}
							}
							
							.arrow-icon {
								margin-left: 12px;
								font-size: 32px;
								font-weight: bold;
								color: #999999;
							}
						}
					}
					
					.right-nav {
						justify-content: flex-end !important;
					}
				}
			}
		}
	}
</style>